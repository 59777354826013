<template>
  <div class="button-section-select" :class="{ 'button-section-select--active': isActive }" @click="$emit('click', id)">
    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'ButtonSectionSelect',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
$button-green: #7ed321;

.button-section-select {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.8em 1.9em 0.7em;
  border-radius: 8px;
  border: solid 3px #ebecea;
  font-size: 1.1em;
  color: #606060;
  font-family: Avenir-Pro-Medium, sans-serif;
  cursor: pointer;

  &:hover {
    border: solid 3px $button-green;
  }

  &--active {
    background-color: $button-green;
    border: solid 3px $button-green;
    color: white;
    font-family: Avenir-Pro-Bold, sans-serif;
  }

  @media screen and (max-width: 721px) {
    white-space: pre;
  }

  @media screen and (max-width: 696px) {
    font-size: 0.8rem !important;
    padding: 0.2em 1em 0.1em;
    border: solid 2px #ebecea;
  }

  @media screen and (max-width: 380px) {
    font-size: 0.7rem !important;
    border: solid 2px #ebecea;
  }

  @media screen and (max-width: 350px) {
    font-size: 0.6rem !important;
  }
}
</style>
